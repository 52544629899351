<template>
  <b-container>
    <PageTitle title="Org Types"/>
    Need to be able to add/edit org types
  </b-container>
</template>

<script>
  import PageTitle from "@/components/shared/PageTitle";

  export default {
    name: "OrgTypes",
    components: {PageTitle}
  }
</script>

<style scoped>

</style>
